<template>
  <div class="container--fluid fill-height">
    <v-container
      fluid
      class="white"
    >
      <v-container class="pa-10">
        <h2 class="text-h3 font-weight-bold text-center">
          {{ $t("sysaga.titulo") }}
        </h2>
        <div class="px-10 pt-10">
          <p class="text-justify">
            {{ $t("sysaga.texto.parte1") }}
          </p>
          <p class="text-justify">
            {{ $t("sysaga.texto.parte2") }}
          </p>
          <p class="text-justify">
            {{ $t("sysaga.texto.parte3") }}
          </p>
          <p>
            <a
              target="_blank"
              href="https://sysaga2.decea.mil.br/consulta"
            >
              <v-btn
                class="primary"
                rounded
              >
                Consulta de processo no SysAGA
              </v-btn>
            </a>
          </p>
        </div>
      </v-container>
    </v-container>

    <v-container class="pa-10">
      <v-card
        rounded="xl"
        flat
        class="pa-10"
      >
        <v-card-text>
          <div class="d-flex justify-center">
            <v-card
              class="mx-auto"
              max-width="400"
            >
              <div>
                <v-img
                  class="white--text align-end"
                  width="100%"
                  src="/img/logos/logo-sysaga-card.png"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                >
                  <v-card-title>
                    {{
                      $t("inicio.cardSysaga.titulo")
                    }}
                  </v-card-title>
                </v-img>
              </div>
              <v-card-text>
                <div class="text-subtitle-1">
                  {{ $t("inicio.cardSysaga.texto") }}
                </div>
              </v-card-text>
              <v-card-actions>
                <v-row no-gutters>
                  <v-col>
                    <a
                      target="_blank"
                      href="https://sysaga2.decea.mil.br/"
                    >
                      <v-btn
                        class="primary"
                        block
                        rounded
                      >
                        {{ $t("button.singIn") }}
                      </v-btn>
                    </a>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import router from '@/router';

export default {
  name: 'Sysaga',
  data: () => ({
    logoSysaga: `${process.env.VUE_APP_URL}/img/logos/logo-sysaga.png`,
    showRegister: true,
  }),
  mounted() {
    //    this.showRegister = !router.app.$keycloak.authenticated;
  },
  methods: {
    register() {
      router.app.$keycloak.register();
    },
  },
};
</script>
<style scoped>
.card_login {
  background-color: #08174a;
}
</style>
